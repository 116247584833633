import React, { useState } from 'react'
import Layout from "../layouts"
import Button from '../components/button/button';
import 'uikit/dist/css/uikit.css'
import { contactHeader } from "../utils/contactInformation"
import Banner from '../components/banner/banner'
import '../styles/contact-us.scss';
import { FaPhoneAlt } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa"
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";


const Contact = () => {


    const [msgError, setMsgError] = useState(false);

    const [error, setError] = useState({
        nameError: '',
        emailError: '',
        phoneError: '',
        messageError: '',
    });

    const [contactMessage, setContactMessage] = useState({
        pageType: 'contact',
        firstName: '',
        email: '',
        phone: '',
        message: ''
    });

    const handleInput = async (event) => {

        event.persist();
        setContactMessage({ ...contactMessage, [event.target.name]: event.target.value })

    }

    const validate = async (e) => {

        let nameError = "";
        let emailError = "";
        let phoneError = "";
        let messageError = "";
        let isNotValid = false;


        if (contactMessage.firstName === "") {
            nameError = "This field is required.";
            isNotValid = true;
        }
        if (contactMessage.email === "") {
            emailError = "This field is required.";
            isNotValid = true;

        }
        if (contactMessage.phone === "") {
            phoneError = "This field is required.";
            isNotValid = true;

        }
        if (contactMessage.message === "") {
            messageError = "This field is required.";
            isNotValid = true;


        }

        setError({ ...error, nameError: nameError, emailError: emailError, phoneError: phoneError, messageError: messageError });
        return isNotValid;

    }

    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {

        event.preventDefault();

        let value = await validate();
        setMsgError(value);

        setLoading(true)

        var body = {
            Token: 'phARMocDrnRvQgKl0zcjMOhD8ZeaWrnn2',
            FormData: JSON.stringify(contactMessage),
            PageType: 'contact'
        };
        //console.log(body);
        // !value && fetch("https://api.phaeria.com/api/home/SendEmailNotification", {

        //     method: "POST",
        //     body: JSON.stringify(body),
        //     headers: {

        //         'Content-Type': 'application/json',
        //         'redirect': 'follow',
        //         'mode': 'no-cors',
        //     }
        // }).then(response => response.text().then(function (responseText) {
        //     setLoading(false);
        //     if (response.status === 200) {
        //         setMessage("Thanks for your message!")
        //     }
        //     else {
        //         setMessage("Something gone wrong...Please try again later!")
        //     }
        // }))

        let spamDomains = ["mailinator", "aosod.com", "guerrillamail", "spam4.me", "pokemail.net", "sharklasers.com", "tcwlx.com", "yopmail.com", "nfstripss.com", "maildrop.cc"];
        let isSpam = 0
        spamDomains.forEach(function (domain, index) {
            if (JSON.stringify(contactMessage).includes(domain)) {
                isSpam = 1;
            }
        });

        if (isSpam == 0 && !value) {
            fetch("https://api.phaeria.com/api/home/SendEmailNotification", {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response =>
                response.text().then(function (responseText) {
                    console.log("response text: " + responseText);
                    setLoading(false);
                    if (response.status === 200) {
                        setMessage("Thanks for your message!")
                    }
                    else {
                        setMessage("Something gone wrong...Please try again later!")
                    }
                })
            )
        }
        else if (value) { // isNotValid == true
            setMessage("")
        } else {
            setMessage("❗ Your message cannot be sent. Please try again with another email address.")
            console.log("Spam.")
        }

    }



    return (

        <Layout
            SEOTitle={"Contact - United Legal Assistance"}
            SEODescription={"United Legal - Contact Us. If you are looking to outsource your Legal Expenses or Claims Management department, we would love to talk."}
            footerDisclaimer={""}>

            <div className="contact-page-container-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2364.9125427726776!2d-3.0063865870693784!3d53.64853157226325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487b3eddb490167b%3A0x14bfe62588f90ce!2sUnited%20Legal%20Assistance!5e0!3m2!1sen!2suk!4v1715945326474!5m2!1sen!2suk" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>

            <div className="contact-grid">
                <div className="uk-grid grid-container" uk-grid>

                    <div className="uk-width-1-2 contact-column half-width">
                        <div className="uk-card uk-card-default uk-card-body" id="contact-info">
                            <h1>Contact Us</h1>
                            <p>If you are looking to outsource your Legal Expenses or Claims Management department, we would love to talk.
                                Just complete the form, and a member of our specialised team will be in touch within one business day.
                                You can also contact us on the number below. </p>

                            <p><FaMapMarkerAlt color="#127F91" fontSize="1.2em" /> 130C Lord Street, Southport, PR9 0AE</p>
                            <p><FaPhoneAlt color="#127F91" fontSize="1.2em" /><a href="tel:01704468030"> 01704 468030</a></p>
                            <p><FaPhoneAlt color="#127F91" fontSize="1.2em" /><a href="tel:01704337462"> Existing Claims - 01704 337462</a></p>
                            <p><FaPhoneAlt color="#127F91" fontSize="1.2em" /><a href="tel:01704337643"> ULR Team - 01704 337643</a></p>
                            <p><FaPhoneAlt color="#127F91" fontSize="1.2em" /><a href="tel:01704337644"> Vehicle Damage Team – 01704 337644</a></p>
                            <p><FaPhoneAlt color="#127F91" fontSize="1.2em" /><a href="tel:01704337645"> Sales Team – 01704 337645</a></p>
                            <p><FaPhoneAlt color="#127F91" fontSize="1.2em" /><a href="tel:01704337646"> Accounts Team – 01704 337646</a></p>

                            <span className="footer-social">
                                <a href="https://www.facebook.com/unitedlegalassistance/" aria-label="Facebook Page"><FaFacebookF fontSize="1.5rem" /></a>
                                <a href="https://twitter.com/united_legal" aria-label="Twitter Page"><FaXTwitter fontSize="1.5rem" /></a>
                                <a href="https://www.linkedin.com/company/united-legal-assistance" aria-label="Twitter Page"><FaLinkedinIn fontSize="1.5rem" /></a>
                            </span>

                        </div>
                    </div>

                    <div className="uk-width-1-2 half-width">
                        <div className="uk-card uk-card-default uk-card-body contact-second-column" id="contact-column-form">
                            <form className="uk-grid-small uk-grid" uk-grid
                                onSubmit={handleSubmit}
                            >
                                <div className="uk-width-1-1@s">
                                    <label className="uk-form-label" htmlFor="form-stacked-text">Name</label>
                                    <input className={`uk-input ${error.nameError !== "" ? 'red-border' : ''}`} type="text" id="form-stacked-text" onChange={handleInput} value={contactMessage.firstName} name="firstName" />
                                </div>

                                {/* <div className="uk-width-1-2@s">
                                    <label className="uk-form-label" htmlFor="form-stacked-text">Surname</label>
                                    <input className={`uk-input ${error.surnameError !== "" ? 'red-border' : ''}`} type="text" id="form-stacked-text" onChange={handleInput} value={contactMessage.surname} name="surname" />
                                </div> */}

                                <div className="uk-width-1-1@s margin-top">
                                    <label className="uk-form-label" htmlFor="form-stacked-text">Email Address</label>
                                    <input className={`uk-input ${error.emailError !== "" ? 'red-border' : ''}`} type="email" id="form-stacked-text" onChange={handleInput} value={contactMessage.email} name="email" />
                                </div>

                                <div className="uk-width-1-1@s margin-top">
                                    <label className="uk-form-label" htmlFor="form-stacked-text">Telephone</label>
                                    <input className={`uk-input ${error.phoneError !== "" ? 'red-border' : ''}`} type="tel" id="form-stacked-text" onChange={handleInput} value={contactMessage.phone} name="phone" pattern="^(?:0|\(?\+33\)?\s?|0033\s?)[1-79](?:[\.\-\s]?\d\d){4}$" />
                                </div>

                                <div className="uk-width-1-1@s margin-top">
                                    <label className="uk-form-label" htmlFor="form-stacked-text">Message</label>
                                    <textarea className={`uk-textarea ${error.messageError !== "" ? 'red-border' : ''}`} rows="6" onChange={handleInput} value={contactMessage.message} name="message" />
                                </div>

                                <div className="uk-width-1-1@s margin-top">
                                    <div className="margin-top contact-send-btn">
                                        <Button
                                            id={"sign-up-submit"}
                                            btnClass={"primary"}
                                            btnType={"primary"}
                                            label={`${loading == false ? 'Submit' : 'Loading...'}`}
                                            isSubmit={"true"}
                                            additionalClass={"full-width-btn"}
                                        ></Button>
                                    </div>
                                </div>

                                {
                                    msgError ? <p className="error-message">One or more fields have an error. Please check and try again.</p> : ""
                                }
                                {
                                    message ? <p className="error-message">{message}</p> : ""
                                }

                            </form>
                        </div>
                    </div>
                </div>
            </div>





        </Layout>


    )

}

export default Contact